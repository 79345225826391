.leaderboard-container {
  font-family: "Poppins";
  max-width: 1170px;
  margin: auto;
  margin-top: 150px;
  color: #fff;
  width: 100%;
  a {
    color: currentColor;
  }
  > .title {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: .75rem;
    padding-right: .75rem;
    margin-bottom: 1.5rem;
    div {
      &:first-child {
        display: flex;
        flex-direction: column;
        span {
          /* vietnamese */
          @font-face {
            font-family: 'Anton';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm3K8-C8CSKlvPfE.woff2) format('woff2');
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
          }
          /* latin-ext */
          @font-face {
            font-family: 'Anton';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm3K9-C8CSKlvPfE.woff2) format('woff2');
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Anton';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm3Kz-C8CSKlv.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          font-family: "Anton", sans-serif;
          font-weight: 900;
          font-size: 2.25rem;
          line-height: 2.5rem;
          &:first-child {
            font-size: 2rem;
            max-width: 68rem;
            text-shadow: 2px 2px #6b7a76;
          }
          &:last-child {
            background: -webkit-linear-gradient(0deg, #005e04, #55d434);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          @media only screen and (min-width: 640px) {
            font-size: 3rem;
            line-height: 1;
            &:first-child {
              font-size: 2.25rem;
            }
          }
          @media only screen and (min-width: 768px) {
            font-size: 3.75rem;
            line-height: 1;
            &:first-child {
              font-size: 3.25rem;
            }
          }
          @media only screen and (min-width: 1024px) {
            font-size: 4.5rem;
            line-height: 1;
            &:first-child {
              font-size: 3rem;
            }
          }
        }
      }
      &:last-child {
        color: rgb(255 255 255);
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    @media only screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
      gap: 1.5rem;
      padding: 0;
      div {
        &:first-child {
          flex: none;
          width: 53%;
        }
      }
    }
  }
  .content {
    display: flex;
    gap: 30px;
    flex-direction: column;
    max-width: 100%;
    width: 1170px;
    padding-bottom: 80px;
    @media only screen and (max-width: 928px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    .sub-title {
      display: flex;
      gap: 12px;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        font-weight: 700;
        font-size: 32px;
      }
    }
    .container {
      display: flex;
      gap: 30px;
      width: 100%;
      @media only screen and (max-width: 928px) {
        flex-direction: column;
        > div {
          width: 100% !important;
        }
      }
      .ranking {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 65%;
        border-radius: 16px;
        .pagination {
          display: flex;
          gap: 4px;
          justify-content: flex-end;
          > div {
            cursor: pointer;
            padding: 8px 16px;
            border-radius: 8px;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            background: #49E4541A;
            &.active,
            &:not(.dot):not(.disable):hover {
              background: rgb(7 42 35);
            }
            &.disable{
              cursor: default;
              background: #0b261b;
            }
            &.dot {
              cursor: default;
            }
          }
        }
        .list-item {
          display: flex;
          flex-direction: column;
          gap: 4px;
          div {
            div {
              width: 30%;
              &:first-child {
                width: 20%;
              }
              &:last-child {
                width: 20%;
              }
            }
          }
          .header-item {
            height: 56px;
            padding: 16px 24px;
            display: flex;
            background-color: #041814;
            align-items: center;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
          }
          .item {
            display: flex;
            height: 48px;
            padding: 12px 24px;
            background: transparent;
            &.top-1 {
              background: linear-gradient(90deg, rgba(255, 230, 0, 0.4) 0%, rgba(255, 229, 0, 0) 100%);
            }
            &.top-2 {
              background: linear-gradient(90deg, rgba(249, 249, 249, 0.3) 0%, rgba(219, 219, 219, 0) 100%);
            }
            &.top-3 {
              background: linear-gradient(90deg, rgba(83, 50, 2, 0.3) 0%, rgba(83, 50, 2, 0) 100%);
            }
            &.light {
              background: #FFFFFF0D;
            }
            &:last-child {
              border-bottom-right-radius: 16px;
              border-bottom-left-radius: 16px;
            }
          }
        }
      }
      .my-rank {
        display: flex;
        gap: 24px;
        background: #FFFFFF0D;
        border-radius: 16px;
        padding: 16px;
        flex-direction: column;
        width: 35%;
        height: fit-content;
        .title {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: flex-start;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(84, 169, 67, 0.1);
          .top {
            display: flex;
            gap: 12px;
            align-items: center;
            height: 40px;
            span {
              font-weight: 700;
              font-size: 32px;
            }
          }
          .bottom {
            font-weight: 500;
            font-size: 16px;
            color: #B1B5C3;
          }
        }
        .tier {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 8px;
          border-radius: 8px;
          height: 69px;
          background: linear-gradient(0deg, rgba(84, 169, 67, 0.1), rgba(84, 169, 67, 0.1)), linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, rgba(84, 169, 67, 0.2) 100%);
          button {
            background-color: transparent;
            border: none;
            font-weight: 700;
            font-size: 24px;
          }
        }
        .rank {
          display: flex;
          justify-content: space-between;
          padding: 6px 8px;
          border-radius: 8px;
          height: 69px;
          background: linear-gradient(0deg, rgba(84, 169, 67, 0.1), rgba(84, 169, 67, 0.1)), linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, rgba(84, 169, 67, 0.2) 100%);
          button {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            &:first-child {
              border-right: 1px solid rgba(84, 169, 67, 0.1);
            }
            &>:first-child {
              font-weight: 700;
              font-size: 24px;
            }
            &>:last-child {
              font-weight: 400;
              font-size: 14px;
              color: #B1B5C3;
            }
          }
        }
      }
    }
  }
  .icons-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}