#asset-page {
  min-height: 100vh;
}
.container.asset.homepage {
  width: auto;
  color: #ffffff;
  min-height: inherit;
  padding: 140px 0 0 0;
  max-width: 924px;
  h2 {
    padding-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
  }
  a {
    color: #ffffff;
  }
  .content {
    .tbl-asset {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: center;
      .assetFt {
        margin-right: 20px;
        table {
          min-height: 150px;
          font-family: "Poppins", sans-serif;
          width: 430px;
          min-width: 50%;
          table-layout: auto;
          border-radius: 12px;
          overflow: hidden;
          tr.empty-line > td {
            text-align: center;
          }
          td:last-child {
            text-align: right;
          }
          .token-item {
            img {
              margin-right: 10px;
              overflow: hidden;
              border-radius: 50%;
              background: var(--white);
              &[alt="NELD"] {
                padding: 3.5px;
              }
            }
          }
          .balance {
            .fiat-amount {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
      }
      .assetNft {
        margin-left: 20px;
        table {
          min-height: 150px;
          font-family: "Poppins", sans-serif;
          width: 450px;
          min-width: 50%;
          table-layout: auto;
          border-radius: 12px;
          overflow: hidden;
          tr.empty-line > td {
            text-align: center;
          }
          th:last-child,
          td:last-child {
            min-width: 180px;
          }
        }
        th {
          min-width: 100px;
        }
        .img {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          div.img-nft {
            margin-right: 10px;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
          .nft-info {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            align-content: center;
            max-width: calc(100% - 60px);
            .nft-name {
              font-weight: 500;
              font-size: 16px;
            }
            .nftId {
              color: #9ffbd6;
              span {
                color: #b1b5c3;
                margin-left: 5px;
              }
            }
          }
        }
        .floor-price {
          .left.price-text {
            font-size: 24px;
            p {
              align-items: center;
              justify-content: flex-end;
              display: flex;
              font-size: 16px;
              img {
                width: 20px;
                height: 20px;
                margin-left: 5px;
              }
            }
          }
        }
        .usd-price p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          align-items: center;
          justify-content: flex-end;
          display: flex;
          padding-right: 24px;
        }
      }
      thead {
        background-color: #041814;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        width: 430px;
        min-width: 50%;
        height: 50px;
        th:first-child {
          border-top-left-radius: 16px;
          text-align: left;
          padding-left: 20px;
        }
        th:last-child {
          border-top-right-radius: 16px;
          text-align: right;
          padding-right: 20px;
        }
      }
      tbody {
        background: #112b24;
      }
      thead,
      tbody > tr {
        &:hover {
          background: #0f3313;
        }
        td {
          padding: 10px 20px;
        }
      }
      tbody > tr:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      }
      .tbl-title {
        color: #49e454;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
        padding-bottom: 2px;
        span {
          border-bottom: 2px solid;
        }
      }
    }
  }
}
