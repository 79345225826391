.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .menus {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .light {
      background-color: var(--greenlight);
      width: 4px;
      height: 4px;
    }
    .menu {
      text-transform: capitalize;
      position: relative;
      padding: 30px;
      .text {
        font-weight: 700;
        transition: all 0.3s ease;
      }
    }
  }
}

.ver-2 {
  .btn-connect {
    padding: 25px !important;
    border-left: 1px solid #1f2931;
  }
  .menu {
    display: flex;
  }
  .text {
    margin-right: 10px;
  }
}
.menu-btn {
  height: 100%;
  .btn {
    color: var(--greenlight);
    span {
      font-weight: 700;
    }
  }
  &.header-container:hover {
    opacity: 1;

    .btn-label.btn-bridge {
      color: var(--greenlight);
      svg {
        path {
          stroke: var(--greenlight);
        }
      }
    }
  }
  &:hover {
    .text {
      color: var(--greenlight);
    }
    .more {
      display: block;
    }
  }
  .more {
    display: none;
    position: absolute;
    top: 72px;
    &.swap {
      top: inherit;
      a {
        display: flex !important;
        align-items: center;
        gap: 12px;
        svg {
          width: 100%;
          &.jumbo {
            width: 30px;
          }
        }
      }
    }
    text-align: center;
    border-radius: 4px;
    z-index: 9;
    min-width: 155px;
    left: 0;
    .wrap-toggle {
      border: 1px solid #49e454;
      background: linear-gradient(
          180deg,
          rgba(84, 169, 67, 0.08) 50%,
          rgba(84, 169, 67, 0.2) 100%
        ),
        linear-gradient(0deg, rgba(4, 21, 8, 0.5), rgba(4, 21, 8, 0.5)),
        linear-gradient(
          180deg,
          rgba(4, 36, 31, 0.8) 0%,
          rgba(4, 36, 31, 0) 100%
        );
      background-color: #000;
      border-radius: 6px;
      padding: 18px;
      &.bridge-container {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &.header-child {
        border-color: var(--sub-greenlight);
      }
      a,
      span {
        font-size: 14px;
        padding: 8px 0;
        text-align: left;
        border-bottom: 1px solid var(--sub-greenlight);
        text-transform: none;
        display: block;
        white-space: nowrap;
        color: var(--white);
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
      span {
        color: var(--white);
        font-weight: 700;
      }
    }
  }
  .btn-header {
    width: 130px;
    &.btn-group-bridge {
      width: auto;
      .more-dropdown {
        a {
          align-items: center;
          font-size: 14px;
          &:hover {
            svg {
              path {
                fill: var(--greenlight)
              }
            }
          }
        }
      }
    }
    .wrap-toggle {
      span {
        white-space: nowrap;
      }
    }
  }
  .btn-label {
    font-weight: 700;
    color: var(--greenlight);
    font-size: 16px;
    &.btn-swap {
      color: inherit;
    }
    &.btn-swap:hover {
      color: var(--greenlight);
    }
  }
}
.non-login {
  opacity: 0.5;
  &:hover {
    opacity: 0.5;
  }
}
.border-right {
  border-right: 1px solid #1f2931;
}

.logged-menu {
  &:hover {
    a:not(.no-active) {
      color: var(--greenlight);
      img:not(.active) {
        display: none;
      }
      img.active {
        display: block !important;
      }
    }
  }
}

.btn-connect {
  cursor: pointer;
  position: relative;
}
.right-wallet-btn {
  display: flex;
  column-gap: 12px;
  height: 100%;
  .btn-connect {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-label {
      &.btn-bridge {
        background: none;
        border: none;
        gap: 8px;
        font-size: 14px;
      }
      background: linear-gradient(
          180deg,
          rgba(84, 169, 67, 0) 0%,
          rgba(84, 169, 67, 0.2) 100%
        ),
        rgba(84, 169, 67, 0.1);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 15px;
      border: 1px solid var(--greenlight);
      border-radius: 6px;
      color: var(--white);
    }
    .active {
      color: white;
      font-weight: 600;
      display: block;
    }
  }
}
.mobile-sub-item {
  padding-top: 24px;
}
#near-wallet-selector-modal {
  .nws-modal-wrapper {
    .nws-modal {
      background-color: #23262F!important;
      border: 1px solid #23262F !important;
      .modal-right {
        .wallet-what.custom-wallet-what {
          margin: 0 !important;
        }
      }
      // .modal-left {
      //   .modal-left-title {
      //     h2 {
      //       color: #49E454 !important;
      //       font-family: "Poppins" !important;
      //       font-weight: 700 !important;
      //     }
      //   }
      // }
      .button-spacing {
        margin: 0 !important;
      }
      .modal-right {
        .wallet-what {
          .content-side {
            h3 {
              font-size: 14px !important;
              color: #FCFCFD !important;
              font-family: 'Poppins' !important;
              font-weight: 700 !important;
            }
            p {
              font-size: 14px !important;
              color: #FCFCFD !important;
              font-family: 'Poppins' !important;
              font-weight: 400 !important;
            }
          }
        }
      }
    }
  }
}
