.card-wrapper {
  background: radial-gradient(
    77.4% 82.93% at 22.6% 26.46%,
    rgba(73, 228, 84, 0.08) 0%,
    rgba(73, 228, 84, 0) 100%
  );
  backdrop-filter: blur(14px);
  border-radius: 20px;
  color: white;
  max-width: 300px;
  width: 100%;
  padding: 35px 15px 25px 15px;
  position: relative;
  transition: all 0.5s ease;
  cursor: pointer;
  .des {
    font-size: 14px;
    letter-spacing: 0.03rem;
    .hightLight {
      color: #55d434;
    }
  }
  .ant-card-body {
    padding: 24px 20px;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-head {
    border: none;
    position: relative;
  }
  &:hover {
    transition: all 0.5s ease;
    background: linear-gradient(
        180deg,
        rgba(106, 169, 67, 0) 0%,
        rgba(106, 169, 67, 0.7) 100%
      ),
      linear-gradient(0deg, rgba(106, 169, 67, 0.3), rgba(106, 169, 67, 0.3));
    transform: translateY(-20px);
  }
}