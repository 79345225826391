.near-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  .ant-pagination {
    .ant-pagination-item {
      border-radius: 6px;
      background: rgba(73, 228, 84, 0.1);
      border: none;
      a {
        color: whitesmoke;
        font-weight: 700;
      }
    }
    .ant-pagination-item-active {
      background: var(--greenlightSecondary);
      a {
        color: var(--black);
      }
    }
    .ant-pagination-item-link {
      background: rgba(73, 228, 84, 0.1);
      border: none;
      color: whitesmoke;
      border-radius: 6px;
    }
    .ant-pagination-options {
      .ant-select-selector {
        background: rgba(73, 228, 84, 0.1);
        border: none;
        color: whitesmoke;
        border-radius: 6px;
      }
    }

    .anticon.anticon-double-right.ant-pagination-item-link-icon,
    .anticon.anticon-double-left.ant-pagination-item-link-icon {
      color: whitesmoke;
    }
    .ant-pagination-item-ellipsis {
      color: whitesmoke;
    }
  }
}
