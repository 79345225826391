.line-defi-wrapper > :first-child {
  position: absolute;
  top: -45%;
  transform: rotate(-90deg);
  left: -25px;
  z-index: 1;
}
.line-wrapper  > :first-child {
  position: absolute;
  top: -45%;
  transform: rotate(90deg);
  right: -25px;
  z-index: 1;
}