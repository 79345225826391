/* @import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap"); */
body {
  margin: 0;
  /* font-family: "Now Light"
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* width */
.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar {
  width: 10px;
}
.ant-notification-notice-close-x {
  color: #FFFFFF !important;
}
/* Track */
.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important; 
  border-radius: 10px;
}
.nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle,
.nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2,
.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side h3,
.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
  color: #FFFFFF !important;
}
/* .nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side {
  background: #FFFFFF !important;
  color: #000 !important;
} */

/* .nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
  background: linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, rgba(84, 169, 67, 0.2) 100%), rgba(84, 169, 67, 0.1) !important;
  padding: 16px 24px;
  color: #FFFFFF !important;
  border: 2px solid #55d434 !important;
  border-radius: 6px !important;
} */
.nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
  background-color: transparent !important;
}
.nws-modal-wrapper .nws-modal .modal-left {
  border-right: 1px solid #313030 !important;
}
.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
  padding: 0!important;
}
/* Handle */
.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar-thumb {
  background: #4db33469 !important; 
  border-radius: 10px;
}
.nws-modal-wrapper .nws-modal-overlay {
  background: #000000b5 !important;
}
/* Handle on hover */
.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar-thumb:hover {
  background: #4db33469 !important; 
}
#near-wallet-selector-modal {
  z-index: 999;
  position: relative;
}
#root {
  z-index: 998;
  position: relative;
}