@font-face{font-family:dm sans;font-style:normal;font-weight:400;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans-Regular.woff) format('woff')}@font-face{font-family:dm sans;font-style:normal;font-weight:400;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans[opsz,wght].woff) format('woff')}@font-face{font-family:dm sans;font-style:italic;font-weight:400;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans-Italic.woff) format('woff')}@font-face{font-family:dm sans;font-style:italic;font-weight:400;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans-Italic[opsz,wght].woff) format('woff')}@font-face{font-family:dm sans;font-style:normal;font-weight:500;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans-Medium.woff) format('woff')}@font-face{font-family:dm sans;font-style:italic;font-weight:500;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans-MediumItalic.woff) format('woff')}@font-face{font-family:dm sans;font-style:normal;font-weight:700;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans-Bold.woff) format('woff')}@font-face{font-family:dm sans;font-style:italic;font-weight:700;src:local('DM Sans'),url(https://fonts.cdnfonts.com/s/29932/DMSans-BoldItalic.woff) format('woff')}
.liquidation-container {
  max-width: 1170px;
  margin: auto;
  margin-top: 150px;
  .container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    min-width: 1170px;
    background: rgba(255, 255, 255, 0.05);
    &.no-login {
      background: inherit;
      border: none;
    }
    &:not(.no-login):before {
      content: "";
      position: absolute;
      mask-image: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px), linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
      -webkit-mask-position-x: 0%, 0%;
      -webkit-mask-position-y: 0%, 0%;
      mask-size: auto, auto;
      mask-repeat: repeat, repeat;
      mask-origin: content-box, border-box;
      mask-clip: content-box, border-box;
      mask-mode: match-source, match-source;
      mask-composite: exclude;
      inset: 0px;
      border-radius: 16px;
      padding: 1px;
      background: linear-gradient(rgba(84, 169, 67, 0.1), rgba(84, 169, 67, 0.2));
      z-index: 2;
    }
    .claim-title {
      font-family: Poppins, sans-serif;
      font-size: 32px;
      font-weight: 700;
      color: rgba(255, 255, 255, 1);
    }
    .reward-info {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-radius: 16px;
      padding: 8px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        mask-image: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px), linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
        -webkit-mask-position-x: 0%, 0%;
        -webkit-mask-position-y: 0%, 0%;
        mask-size: auto, auto;
        mask-repeat: repeat, repeat;
        mask-origin: content-box, border-box;
        mask-clip: content-box, border-box;
        mask-mode: match-source, match-source;
        mask-composite: exclude;
        inset: 0px;
        border-radius: 16px;
        padding: 1px;
        background: linear-gradient(180deg, rgba(84, 169, 67, 0.4), rgb(84, 169, 67, 0.6));
      }
      .info-value {
        font-family: "Poppins";
        .info-value-title {
          font-size: 14px;
          font-weight: 500;
          color: rgba(177, 181, 195, 1);
        }
        .info-value-amount {
          font-size: 20px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
        }
      }
      .info-detail {
        display: flex;
        gap: 180px;
      }
    }
    .btn-groups {
      > div:not(.wrap-popup) {
        height: 10vh !important;
      }
      button {
        width: fit-content;
        background: linear-gradient(0deg, rgba(84, 169, 67, 0.1), rgba(84, 169, 67, 0.1)), linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, rgba(84, 169, 67, 0.2) 100%);
        &:hover {
          color: #fff;
          background: linear-gradient(0deg, rgba(84, 169, 67, 0.1), rgba(84, 169, 67, 0.1)), linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, rgba(84, 169, 67, 0.2) 100%);
          box-shadow: rgb(84, 169, 67) 2px 2px 15px 1px;
        }
        &.button-notification.btn-login-popup {
          width: 237px;
          cursor: pointer;
          text-transform: capitalize;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 24px;
          background: linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, rgba(84, 169, 67, 0.2) 100%), rgba(84, 169, 67, 0.1);
          border-radius: 6px;
          border: 1px solid var(--greenlight);
          font-weight: 700;
          font-size: 13px;
          width: 160px;
          margin: 0 6px;
          transition: 0.4s all;
          &.button-gray {
            background: #929191;
            border: 1px solid #929191;
          }
          &:hover {
            opacity: 0.84;
            box-shadow: none;
          }
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 3;
      max-width: 650px;
      .popup.notification .wrap-button {
        justify-content: space-evenly;
      }
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.App {
  background: url("/images/markets/market-bg.png") no-repeat center !important;
  background-size: cover !important;
}
.loading-spin-wrapper .ant-spin-blur .App {
  opacity: 0.75;
}
